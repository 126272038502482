<template>
    <div class="classData">
        <div class="class_nav flex-align-between" v-if="$route.query.role == 2">
            <div class="navigation nav_size flex-align-center"
                :class="replace_index == index && !testBtn ? 'home_pageB' : 'home_pageA'" v-for="(item, index) in nav_list"
                :key="index" @click="nav_chang(index)">{{ item }}</div>
            <div class="navigation nav_size flex-align-center" :class="testBtn ? 'home_pageB' : 'home_pageA'"
                @click="gotestBtn">测评报告</div>
        </div>
        <div class="myobox" v-if="$route.query.type == 'class' && $route.query.inside != 0">

            <div class="selectbox flex-align-between">
                <div class="gradesel flex-align">
                    <span class="selectName">学年：</span>
                    <el-select v-model="termValue" placeholder="请选择" @change="termChange" size='small'>
                        <el-option v-for="(item, index) in termOptions" :key="index" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="gradesel flex-align">
                    <span class="selectName">年级：</span>
                    <el-select v-model="gradevalue" placeholder="请选择" :popper-append-to-body="false" @change="gradeexecute"
                        size='small'>
                        <el-option v-for="item in gradeoptions" :key="item.value" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                </div>
                <div class="gradesel flex-align">
                    <span class="selectName">班级：</span>
                    <el-select v-model="classvalue" placeholder="请选择" :popper-append-to-body="false" @change="classexecute"
                        size='small'>
                        <el-option v-for="item in classoptions" :key="item.value" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>

        <div v-if="replace_index == 0" class=" stu_seen flex-align-between">
            <div class="leftSide flex-column-between">
                <div class="graph1 frame">
                    <div class="basicData flex-column-between">
                        <div class="headline headline_interval flex-align-between">
                            <span class="font">基本数据</span>
                        </div>
                        <div class="substance flex-column-between">
                            <div class="average_Sta flex-align-between">
                                <div class="personalData">
                                    <img :src="essential_portrait" v-if="essential_portrait" @error="onErrorImg" />
                                    <img src="../../assets/myactivity/tea-woman.png" v-else />
                                    <div>
                                        教师：
                                        <span v-for="(item, index) in essential_teacherNameList" :key="index"> {{ item
                                        }}</span>
                                    </div>
                                </div>
                                <div class="basics">
                                    <ul class="">
                                        <li class="flex-align-between">
                                            <span>班级人数</span>
                                            <span>{{ essential_count }}</span>
                                        </li>
                                        <li class="flex-align-between">
                                            <span>我的直播</span>
                                            <span>{{ essential_live }}</span>
                                        </li>
                                        <li class="flex-align-between">
                                            <span>我的作品</span>
                                            <span>{{ essential_myWords }}</span>
                                        </li>
                                        <li class="flex-align-between">
                                            <span>学生作品</span>
                                            <span>{{ essential_StuWords }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!-- <div class="curriculum_Sta flex-align-center">
                            <span>开课次数</span>
                            <div class="flex-align-between">
                                <span>累计：{{ essential_schoolBeginsA }}</span>
                                <span>|</span>
                                <span>当日：{{ essential_schoolBegins }}</span>
                            </div>
                        </div> -->
                        </div>
                    </div>
                    <div class="panel-footer"></div>
                </div>
                <div class="graph2 frame">
                    <div class="basicData flex-column-between">
                        <div class="headline headline_interval flex-align-between">
                            <span class="font">学生待提升最多的字</span>
                        </div>
                        <div class="substance" id="elevate" v-if="elevate_show" key="elevate"></div>
                        <div class="substance flex-align-center" v-else>
                            <placeholderScreen></placeholderScreen>
                        </div>
                    </div>
                    <div class="panel-footer">

                    </div>
                </div>
                <div class="graph3 frame">
                    <div class="basicData flex-column-between">
                        <div class="headline headline_interval flex-align-between">
                            <span class="font">学生作品等级分布</span>
                        </div>
                        <div class="substance" id="grade"></div>
                    </div>
                    <div class="panel-footer">

                    </div>
                </div>
            </div>
            <div class="middle flex-column-between">
                <div class="graph6 frame">
                    <div class="basicData flex-column-between">
                        <div class="headline headline_interval flex-align-between">
                            <span class="font">上课统计</span>
                            <div>
                                <span class="font hand" @click="recentShow_click1"
                                    :style="{ color: !recentShow ? '#66CCFF' : '#fff' }">直接上课</span>
                                <span class="font division">|</span>
                                <span class="font hand" @click="recentShow_click2"
                                    :style="{ color: recentShow ? '#66CCFF' : '#fff' }">互动课堂</span>
                            </div>
                        </div>
                        <div class="substance flex-column-between">
                            <div class="Legend1" v-if="recentShow">
                                <div class="average_Sta" id="recent" v-if="!direct_class_show" key="recent"></div>
                                <div class="average_Sta flex-align-center" v-if="direct_class_show">
                                    <placeholderScreen></placeholderScreen>
                                </div>
                            </div>
                            <div class="Legend2" v-else>
                                <div class="average_Sta" id="immediateClass" v-if="!interaction_class_show"
                                    key="immediateClass"></div>
                                <div class="average_Sta flex-align-center" v-else>
                                    <placeholderScreen></placeholderScreen>
                                </div>
                            </div>
                            <div class="curriculum_Sta flex-align-center">
                                <!-- <span>上课时长</span>
                            <div class="flex-align-between">
                                <span>累计：{{ clasTime_Sum }}</span>
                                <span>|</span>
                                <span>当日：{{ dailyTencent }}</span>
                            </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="panel-footer"></div>
                </div>
                <div class="graph7 frame">
                    <div class="basicData flex-column-between">
                        <div class="headline headline_interval flex-align-between">
                            <span class="font">学生汉字识写与素养水平</span>
                            <el-select v-model="nameV" placeholder="请选择" :popper-append-to-body="false" @change="changeStu"
                                v-if="$route.query.inside != 0">
                                <el-option v-for="(item, index) in options" :key="index" :label="item.name"
                                    :value="item.name">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="substance flex-align-between">
                            <div class="calligraphyLevel" id="calligraphyLevel"></div>
                            <div class="attainment" id="attainment"></div>
                            <div class="legend flex-column">
                                <div class="legendItem flex-align">
                                    <div class="before"></div>
                                    <span>前测</span>
                                </div>
                                <!-- <div class="legendItem flex-align">
                                <div class="center"></div>
                                <span>中测</span>
                            </div> -->
                                <div class="legendItem flex-align">
                                    <div class="after"></div>
                                    <span>后测</span>
                                </div>
                                <div class="legendItem flex-align">
                                    <div class="all"></div>
                                    <span>全国常模</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel-footer"></div>
                </div>
                <div class="graph8 frame">
                    <div class="basicData flex-align-between">
                        <div class="cultivate flex-column-between">
                            <div class="headline headline_interval flex-align-between">
                                <span class="font">我的活动</span>
                            </div>
                            <div class="substance flex-align-between">
                                <div class="Font" id="myFont"></div>
                                <div class="FontData flex-align-center">
                                    <ul>
                                        <li class="flex-align-between">
                                            <span>参与活动：</span>
                                            <span>{{ activity_amount }}</span>
                                        </li>
                                        <li class="flex-align-between">
                                            <span>发帖数：</span>
                                            <span>{{ issue_amount }}</span>
                                        </li>
                                        <li class="flex-align-between">
                                            <span>点赞数：</span>
                                            <span>{{ like_amount }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="evaluate flex-column-between">
                            <div class="headline headline_interval flex-align-between">
                                <span class="font">我的作品评价</span>
                            </div>
                            <div class="substance flex-align-center" v-if="tea_category.length != 0">
                                <ul>
                                    <li class="flex-column-between">
                                        <span>优秀作品数：{{ tea_category[0].value }}</span>
                                        <div class="">{{ tea_excellentWord }}</div>
                                    </li>
                                    <li class="flex-column-between">
                                        <span>良好作品数：{{ tea_category[1].value }}</span>
                                        <div class="">{{ tea_goodWord }}</div>
                                    </li>
                                    <li class="flex-column-between">
                                        <span>待提升作品数：{{ tea_category[2].value }}</span>
                                        <div class="">{{ tea_promoteWord }}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="panel-footer"></div>
                </div>
            </div>
            <div class="rightSide flex-column-between">
                <div class="graph4 frame">
                    <div class="basicData flex-column-between">
                        <div class="headline headline_interval flex-align-between">
                            <span class="font">学生互动参与情况</span>
                        </div>
                        <div class="substance flex-column-between" v-if="participationData.length != 0">
                            <div class="topThree flex-column-center">
                                <div class="first flex-align-center" v-if="participationData[0]">
                                    <div class="Avatar flex-column-center">
                                        <img src="../../assets/datascreen/crown1.png" />
                                        <!-- <img :src="'https://yunapi.cnuzi.com/uploads/' + participationData[0].studentAvatar"
                                        v-if="participationData[0].studentAvatar" @error="onErrorImg2(0)" />
                                    <img :src=" participationData[0].sex == 1? require('../../assets/datascreen/boy.png'): require('../../assets/datascreen/girl.png')"
                                        v-else /> -->
                                        <img
                                            :src="participationData[0].sex == 1 ? require('../../assets/datascreen/boy.png') : require('../../assets/datascreen/girl.png')" />
                                        <div class="wes stuname">{{ participationData[0].studentName }}</div>
                                        <div class="participationData">
                                            <ul>
                                                <li>
                                                    <span>参与互动：</span>
                                                    <span>{{ participationData[0].countJoin }} 次</span>
                                                </li>
                                                <li>
                                                    <span>正确率：</span>
                                                    <span>{{ Math.round(Number((participationData[0].rightRate *
                                                        100).toFixed(2))) }}%</span>
                                                </li>
                                                <li>
                                                    <span>作品：</span>
                                                    <span>{{ participationData[0].workShow }}张</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="follow-up flex-align-evenly">
                                    <div class="second flex-align-between" v-if="participationData[1]">
                                        <div class="Avatar flex-column-center">
                                            <img src="../../assets/datascreen/crown2.png" />
                                            <!-- <img :src="'https://yunapi.cnuzi.com/uploads/' +participationData[1].studentAvatar"
                                            v-if="participationData[1].studentAvatar" @error="onErrorImg2(1)" />
                                        <img :src="participationData[1].sex == 1? require('../../assets/datascreen/boy.png'): require('../../assets/datascreen/girl.png')"
                                            v-else /> -->
                                            <img
                                                :src="participationData[1].sex == 1 ? require('../../assets/datascreen/boy.png') : require('../../assets/datascreen/girl.png')" />
                                            <div class="wes stuname">
                                                {{ participationData[1].studentName }}
                                            </div>
                                            <div class="participationData">
                                                <ul>
                                                    <li>
                                                        <span>参与互动：</span>
                                                        <span>{{ participationData[1].countJoin }} 次</span>
                                                    </li>
                                                    <li>
                                                        <span>正确率：</span>
                                                        <span>{{ Math.round(Number((participationData[1].rightRate *
                                                            100).toFixed(2))) }}%
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>作品：</span>
                                                        <span>{{ participationData[1].workShow }}张</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="thirdly flex-align-between" v-if="participationData[2]">
                                        <div class="Avatar flex-column-center">
                                            <img src="../../assets/datascreen/crown3.png" />
                                            <!-- <img :src="'https://yunapi.cnuzi.com/uploads/' +participationData[2].studentAvatar"
                                            v-if="participationData[2].studentAvatar" @error="onErrorImg2(2)" />
                                        <img :src="participationData[2].sex == 1? require('../../assets/datascreen/boy.png'): require('../../assets/datascreen/girl.png')"
                                            v-else /> -->
                                            <img
                                                :src="participationData[2].sex == 1 ? require('../../assets/datascreen/boy.png') : require('../../assets/datascreen/girl.png')" />
                                            <div class="wes stuname">
                                                {{ participationData[2].studentName }}
                                            </div>
                                            <div class="participationData">
                                                <ul>
                                                    <li>
                                                        <span>参与互动：</span>
                                                        <span>{{ participationData[2].countJoin }} 次</span>
                                                    </li>
                                                    <li>
                                                        <span>正确率：</span>
                                                        <!-- Math.round( Number((item.rightRate * 100).toFixed(2))) -->
                                                        <span>{{ Math.round(Number((participationData[2].rightRate *
                                                            100).toFixed(2))) }}%</span>
                                                    </li>
                                                    <li>
                                                        <span>作品：</span>
                                                        <span>{{ participationData[2].workShow }}张</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sort">
                                <vue-seamless-scroll :data="participationData" :class-option="optionSingleHeightTime"
                                    class="participation-warp">
                                    <ul class="item">
                                        <li class="flex-align" v-for="(item, index) in participationData.slice(3)"
                                            :key="index">
                                            <img :src="'https://yunapi.cnuzi.com/uploads/' + item.studentAvatar"
                                                v-if="item.studentAvatar" /> <img
                                                :src="item.sex == 1 ? require('../../assets/datascreen/boy.png') : require('../../assets/datascreen/girl.png')"
                                                v-else />
                                            <div class="flex-column-between">
                                                <span class="stuname">{{ item.studentName }}</span>
                                                <div class="flex-align itemSon">
                                                    <span>参与互动：{{ item.countJoin }} 次</span>
                                                    <span>正确率：{{ Math.round(Number((item.rightRate * 100).toFixed(2)))
                                                    }}%</span>
                                                    <!-- * 100% -->
                                                    <span>作品：{{ item.workShow }} 张</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </vue-seamless-scroll>
                            </div>
                        </div>
                        <div class="substance flex-align-center" v-else>
                            <placeholderScreen></placeholderScreen>
                        </div>
                    </div>
                    <div class="panel-footer"></div>
                </div>
                <div class="graph5 frame">
                    <div class="basicData flex-column-between">
                        <div class="headline headline_interval flex-align-between">
                            <span class="font">作品实时动态（含师、生作品）</span>
                        </div>
                        <div class="substance" v-if="wordsDatas.length != 0">
                            <wordsRoll :wordsData="wordsDatas" ref="child" @fatherMethod="fatherMethod">
                            </wordsRoll>
                        </div>
                        <div class="substance flex-align-center" v-else>
                            <placeholderScreen></placeholderScreen>
                        </div>
                    </div>
                    <div class="panel-footer"></div>
                </div>
            </div>
            <div class="appraisalbox">
                <el-dialog :visible.sync="centerDialogVisible" :modal-append-to-body="false" :append-to-body="false"
                    :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" center>
                    <appraisal :word="dialog_words" ref="appraisal"></appraisal>
                    <img class="close" src="../../assets/datascreen/close.png" @click="close" />
                </el-dialog>
            </div>
        </div>
        <classData_stuUse v-if="replace_index == 1" :termKey_value="termValue" :classId_value="class_bindId"
            :class_Name="classvalue" ref="myChild_stuUse"></classData_stuUse>
    </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import wordsRoll from "../../Common_components/wordRoll/wordsRoll";
import appraisal from "../../Common_components/appraisals/appraisal";
import classData_stuUse from "../DataScreen/classData_stuUse.vue";
import placeholderScreen from "./placeholderBox/placeholderScreen.vue";
export default {
    data() {
        return {
            centerDialogVisible: false,
            // ----基础数据
            teacherId: "",
            //班级人数
            essential_count: 0,
            //我的直播
            essential_live: 0,
            //我的作品
            essential_myWords: 0,
            //学生作品
            essential_StuWords: 0,
            //头像
            essential_portrait: "",
            //班级名称
            essential_className: "",
            //教师名称
            essential_teacherName: "",
            //教师数组
            essential_teacherNameList: [],
            //上课时长
            essential_classTime: 0,
            //开课次数累计总数
            essential_schoolBeginsA: 0,
            //开课次数累计每日
            essential_schoolBegins: 0,
            //----我的培训
            //参与活动数
            activity_amount: 0,
            //发帖数
            issue_amount: 0,
            //点赞数
            like_amount: 0,
            //----学生作品等级分布
            category: [],
            //---最近7次上课统计
            // 上课时长
            lengthData: [],
            //参与率
            joinRateData: [],
            //平均正确率
            rightRateData: [],
            //字
            characterData: [],
            //互动人数
            interactionData: [],
            // 最近7次上课时长
            clasTime_Sum: 0,
            //当天上课时长
            dailyTencent: 0,
            //---学生待提升最多的字
            promoteData: [],
            promoteName: [],
            //----学生互动参与情况
            participationData: [],
            tea_wordsNum: 0,
            //----我的作品评价
            tea_category: [],
            tea_excellentWord: "",
            tea_goodWord: "",
            tea_promoteWord: "",
            wordsDatas: [],
            // 放大图片
            dialog_words: [],
            //----学生汉学书写能力及素养水平
            //学生书写水平
            write_indicator: [],
            write_Data: [],
            //学生汉字素养水平
            Hanzi_indicator: [],
            Hanzi_Data: [],
            nameV: "全部",
            options: [{
                id: '',
                name: "全部",
                sex: '',
            },],
            gradevalue: "",
            classvalue: "",
            gradeoptions: [],
            classoptions: [],
            class_bindId: "", // 班级id
            class_orgId: "", // 组织结构id
            participateInType0: true,
            participateInType1: true,
            participateInType2: true,
            participateInType: true,
            // participateInIndex: null,
            defaultImg1: require("../../assets/myactivity/tea-woman.png"),
            termOptions: [],
            termValue: '',
            defaultData1: [86, 76, 78, 75],
            defaultData2: [75, 65, 78, 75, 67],
            //直接上课
            immediateShow: true,
            recentShow: false,
            //学生应用
            nav_list: ['首页', '学生应用'],
            testBtn:false,
            replace_index: 0,
            //直接上课show
            direct_class_show: '',
            //互动课堂show
            interaction_class_show: '',
            //作品列表show
            wordsRoll_show: '',
            //学生待提升最多的字show
            elevate_show: '',
        };
    },
    computed: {
        optionSingleHeightTime() {
            return {
                step: 0.4,
                openWatch: true,
            };
        },
    },
    components: {
        vueSeamlessScroll,
        wordsRoll,
        appraisal,
        classData_stuUse,
        placeholderScreen
    },
    //方法
    methods: {
        drawRecent() {
            this.$echarts.init(document.getElementById("recent")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("recent"));
            myChart.setOption({
                tooltip: {
                    trigger: "axis",
                    formatter: "{b}</br>{a0}：{c0} 分钟</br>{a1}：{c1}%</br>{a2}：{c2}%",
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    }
                },
                legend: {
                    itemWidth: this.graphicRatio(12),
                    itemHeight: this.graphicRatio(12),
                    data: [{
                        name: "上课时长",
                        icon: "rect",
                        textStyle: {
                            color: "#fff",
                            fontSize: this.graphicRatio(12),
                        },
                    },
                    {
                        name: "参与率",
                        icon: "circle",
                        textStyle: {
                            color: "#fff",
                            fontSize: this.graphicRatio(12),
                        },
                    },
                    {
                        name: "正确率",
                        icon: "circle",
                        textStyle: {
                            color: "#fff",
                            fontSize: this.graphicRatio(12),
                        },
                    },
                    ],
                },
                grid: {
                    top: "22%",
                    left: "5%",
                    right: "8%",
                    bottom: "3%",
                    containLabel: true,
                },

                xAxis: {
                    type: "category",
                    axisLabel: {
                        color: "#fff",
                        fontSize: this.graphicRatio(12),
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },

                    data: this.characterData,
                },
                yAxis: [{
                    type: "value",
                    axisLine: {
                        // onZero: false,
                        lineStyle: {
                            color: "#fff",
                        },
                    },
                    axisLabel: {
                        color: "#fff",
                        fontSize: this.graphicRatio(12),
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#2993FF",
                        },
                    },
                    name: "时长分钟",
                    nameTextStyle: {
                        color: "#fff",
                        fontSize: this.graphicRatio(12),
                    },
                },
                {
                    type: "value",
                    // axisPointer: {
                    //   type: "none",
                    // },

                    axisLabel: {
                        color: "#fff",
                        formatter: "{value}%", //字符串模板
                        fontSize: this.graphicRatio(12),
                    },
                    nameTextStyle: {
                        color: "#fff",
                        fontSize: this.graphicRatio(12),
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },

                    name: "比例%",
                    nameTextStyle: {
                        color: "#fff",
                        fontSize: this.graphicRatio(12),
                    },
                    data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                },
                ],
                series: [{
                    name: "上课时长",
                    type: "bar",
                    barWidth: "30%",
                    barCategoryGap: "50%",
                    yAxisIndex: 0, //y轴的index
                    // label:{
                    //     show: true,
                    //     position: 'top' ,
                    //     fontSize: this.graphicRatio(12),
                    //     color: "#fff",
                    //     formatter: (params)=> {
                    //         console.log('params',that.interactionData[params.dataIndex]);
                    //         return that.interactionData[params.dataIndex] +'次';
                    //     },
                    // },
                    itemStyle: {
                        color: function (params) {
                            return new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: "rgba(41, 147, 255, 1)",
                            },
                            {
                                offset: 1,
                                color: "rgba(135, 175, 255, 1)",
                            },
                            ]);
                        },
                    },

                    data: this.lengthData,
                },
                {
                    name: "参与率",
                    type: "line",
                    yAxisIndex: 1, //y轴的index
                    itemStyle: {
                        color: "#27FCA9",
                    },
                    data: this.joinRateData,
                },
                {
                    name: "正确率",
                    type: "line",
                    yAxisIndex: 1, //y轴的index
                    itemStyle: {
                        color: "#ff9b01",
                    },
                    data: this.rightRateData,
                },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //直接上课统计
        drawImmediate(date, value) {
            this.$echarts.init(document.getElementById("immediateClass")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("immediateClass"));
            myChart.setOption({

                dataZoom: [{
                    textStyle: {
                        color: '#fff',
                        fontSize: this.graphicRatio(12),
                    },
                    show: true,
                    height: this.graphicRatio(8),
                    bottom: '6%',
                    startValue: date.slice(-7, -6)[0],
                    endValue: date.slice(-1)[0],
                }],
                tooltip: {
                    trigger: "axis",
                    formatter: "{b}<br/>上课次数：{c} 次",
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    }
                },
                grid: {
                    // top: "22%",
                    top: "10%",
                    left: "5%",
                    right: "8%",
                    bottom: "15%",
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        color: "#fff",
                        interval: 0,
                        fontSize: this.graphicRatio(12),
                    },
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    // data: ['2023-01-01', '2023-01-02', '2023-01-03', '2023-01-04', '2023-01-05', '2023-01-06', '2023-01-07', '2023-01-08', '2023-01-09', '2023-01-10', '2023-01-11', '2023-01-12', '2023-01-13', '2023-01-14', '2023-01-15', '2023-01-16', '2023-01-17', '2023-01-18', '2023-01-19', '2023-01-20', '2023-01-21', '2023-01-22', '2023-01-23', '2023-01-24', '2023-01-25', '2023-01-26', '2023-01-27', '2023-01-28', '2023-01-29', '2023-01-30', '2023-01-31']
                    data: date
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        // onZero: false,
                        lineStyle: {
                            color: "#fff",
                        },
                    },
                    axisLabel: {
                        color: "#fff",
                        fontSize: this.graphicRatio(12),
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#1769AB",
                        },
                    },
                },
                series: [
                    {
                        // data: [120, 200, 150, 80, 70, 700, 130, 120, 200, 150, 80, 70, 110, 130, 120, 200, 150, 80, 70, 110, 130, 120, 200, 150, 80, 70, 110, 130, 23, 11, 26],
                        data: value,
                        type: 'bar',
                        // barWidth: "40%",
                        barCategoryGap: "50%",
                        itemStyle: {
                            color: "#66CCFF",
                        },
                    }
                ]
            })
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },

        // 学生待提升最多的字
        drawElevate() {
            this.$echarts.init(document.getElementById("elevate")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("elevate"));
            myChart.setOption({
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    }

                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: [{
                    show: false,
                    type: "category",
                    data: this.promoteName,
                    axisTick: {
                        alignWithLabel: true,
                    },
                },],
                yAxis: [{
                    show: false,
                    type: "value",
                },],
                series: [{
                    name: "数量",
                    type: "bar",
                    barWidth: "50%",
                    barCategoryGap: "50%",
                    label: {
                        show: true,
                        position: "top",
                        color: "rgba(43, 237, 153, 1)",
                        fontSize: this.graphicRatio(12),
                        formatter: "{b}" + "{c}",
                    },
                    itemStyle: {
                        borderRadius: [this.graphicRatio(25), this.graphicRatio(25), this.graphicRatio(25), this.graphicRatio(25)],

                        color: function (params) {
                            return new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: "rgba(43, 237, 153, 1)",
                            },
                            {
                                offset: 1,
                                color: "rgba(78, 138, 255, 1)",
                            },
                            ]);
                        },
                    },
                    backgroundStyle: {
                        color: "rgba(180, 180, 180, 0.2)",
                    },
                    data: this.promoteData,
                },],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //学生写作水平
        drawRadar1() {
            let splitColor = "#167374";
            this.$echarts.init(document.getElementById("calligraphyLevel")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("calligraphyLevel")
            );
            myChart.setOption({
                color: ["#FFD900", "#FF7B00", "#27C662", "#00A7F5"],
                tooltip: {
                    show: true, // 弹层数据去掉
                    showContent: true,
                    textStyle: {
                        fontSize: this.graphicRatio(13),
                    },
                    formatter: (params) => {
                        let relVal = params.name + '<br/>';
                        for (let i = 0; i < params.data.value.length; i++) {
                            relVal +=
                                that.write_indicator[i].name +
                                " : " +
                                that.write_Data[i] +
                                "%" +
                                "<br/>";
                        }
                        return relVal;
                    },
                },
                title: {
                    text: "学生书写水平",
                    x: "center",
                    y: "top",
                    textAlign: "left",
                    textStyle: {
                        color: "#fff",
                        fontSize: this.graphicRatio(12),
                    },
                },
                radar: {
                    center: ["50%", "54%"], // 外圆的位置
                    radius: "54%",
                    axisName: {
                        color: "#fff",
                        fontSize: this.graphicRatio(11),
                        fontWeight: 400,
                        fontFamily: "PingFangSC-Regular,PingFang SC",
                        fontStyle: "normal",
                    },
                    indicator: this.write_indicator,
                    // indicator: 100,
                    splitArea: {
                        // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                        show: true,
                        areaStyle: {
                            // 分隔区域的样式设置。
                            color: ["RGBA(27, 38, 80, 1)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                        },
                    },
                    axisLine: {
                        // 指向外圈文本的分隔线样式
                        lineStyle: {
                            color: splitColor,
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            type: "solid",
                            color: splitColor, // 分隔线颜色
                            width: this.graphicRatio(1), // 分隔线线宽
                        },
                    },
                },
                series: [
                    {
                        type: "radar",
                        symbolSize: this.graphicRatio(10),
                        symbol: "none",
                        itemStyle: {
                            borderColor: "rgba(66, 242, 185, 1)",
                            color: "#fff",
                            borderWidth: this.graphicRatio(0.2),
                        },
                        lineStyle: {
                            width: this.graphicRatio(1), // 分隔线线宽
                            color: "#00A7F5",
                        },
                        data: [{
                            name: "全国常模",
                            value: this.defaultData1,
                            areaStyle: {
                                color: "rgba(0, 167, 245, .8)"
                            },
                        },],
                    },
                    {
                        type: "radar",
                        symbolSize: this.graphicRatio(10),
                        symbol: "none",
                        itemStyle: {
                            borderColor: "FFD900",
                            color: "#fff",
                            borderWidth: this.graphicRatio(0.2),
                        },
                        lineStyle: {
                            width: 1,
                            color: '#FFD900',
                        },
                        data: [{
                            name: `${this.nameV == '全部' ? this.gradevalue : this.nameV + '同学'}前测:`,
                            value: this.write_Data,
                            label: {
                                formatter: '{value}%'
                            },
                            areaStyle: {
                                color: "rgba(255, 217, 0, 1)"
                            },
                        },],
                    },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //学生汉字水平
        drawRadar2() {
            let splitColor = "#167374";
            this.$echarts.init(document.getElementById("attainment")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("attainment"));
            myChart.setOption({
                color: ["#3D91F7", "#61BE67"],
                tooltip: {
                    show: true, // 弹层数据去掉
                    textStyle: {
                        fontSize: this.graphicRatio(13),
                    },
                    formatter: (params) => {
                        let relVal = params.name + '<br/>';
                        for (let i = 0; i < params.data.value.length; i++) {
                            relVal +=
                                that.Hanzi_indicator[i].name +
                                " : " +
                                that.Hanzi_Data[i] +
                                "%" +
                                "<br/>";
                        }
                        return relVal;
                    },
                },
                title: {
                    text: "学生汉字素养水平",
                    x: "center",
                    y: "top",
                    textAlign: "left",
                    textStyle: {
                        color: "#fff",
                        fontSize: this.graphicRatio(11),
                    },
                },
                radar: {
                    center: ["50%", "54%"], // 外圆的位置
                    radius: "54%",
                    axisName: {
                        color: "#fff",
                        fontSize: this.graphicRatio(11),
                        fontWeight: 400,
                        fontFamily: "PingFangSC-Regular,PingFang SC",
                        fontStyle: "normal",
                    },
                    indicator: this.Hanzi_indicator,
                    splitArea: {
                        // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                        show: true,
                        areaStyle: {
                            // 分隔区域的样式设置。
                            color: ["RGBA(27, 38, 80, 1)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                        },
                    },
                    axisLine: {
                        // 指向外圈文本的分隔线样式
                        lineStyle: {
                            color: splitColor,
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            type: "solid",
                            color: splitColor, // 分隔线颜色
                            width: this.graphicRatio(1),  // 分隔线线宽
                        },
                    },
                },
                series: [
                    {
                        type: "radar",
                        symbolSize: this.graphicRatio(10),
                        symbol: "none",
                        itemStyle: {
                            borderColor: "rgba(66, 242, 185, 1)",
                            color: "#fff",
                            borderWidth: this.graphicRatio(0.2),
                        },
                        lineStyle: {
                            width: this.graphicRatio(1),
                            color: "#00A7F5",
                        },
                        data: [{
                            value: this.defaultData2,
                            name: "全国常模",
                            areaStyle: {
                                color: "rgba(0, 167, 245, .8)"
                            },
                        },],
                    },
                    {
                        type: "radar",
                        symbolSize: this.graphicRatio(10),
                        symbol: "none",
                        itemStyle: {
                            borderColor: "rgba(66, 242, 185, 1)",
                            color: "#fff",
                            borderWidth: this.graphicRatio(0.2),
                        },
                        lineStyle: {
                            width: 1,
                            color: "rgba(66, 242, 185, 1)",
                        },
                        data: [{
                            value: this.Hanzi_Data,
                            name: `${this.nameV == '全部' ? this.gradevalue : this.nameV + '同学'}前测:`,
                            areaStyle: {
                                color: "rgba(255, 217, 0, .8)"
                            },
                        }]
                    }

                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },

        //学生作品等级分布
        drawGrade() {
            this.$echarts.init(document.getElementById("grade")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("grade"));
            var total =
                that.category[0].value +
                that.category[1].value +
                that.category[2].value; // 数据总数
            var datas = [];
            that.category.forEach((value) => {
                datas.push(value.value);
            });
            myChart.setOption({
                xAxis: {
                    max: total,
                    splitLine: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                },
                grid: {
                    left: this.graphicRatio(150),
                    top: "5%", // 设置条形图的边距
                    right: this.graphicRatio(80),
                    bottom: "5%",
                },
                yAxis: [{
                    type: "category",
                    inverse: false,
                    data: that.category,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                },],
                series: [{
                    type: "bar",
                    barWidth: this.graphicRatio(28),
                    silent: true,
                    itemStyle: {
                        color: "rgba(0, 204, 255, 0.6)",
                    },
                    label: {
                        formatter: "{b}",
                        color: "#fff",
                        fontSize: this.graphicRatio(12),
                        // textStyle: {
                        //       color: "#fff",
                        //       fontSize: 14
                        // },
                        position: "left",
                        distance: this.graphicRatio(20), // 向右偏移位置
                        show: true,
                    },
                    data: that.category,
                    z: 1,
                    animationEasing: "elasticOut",
                },

                {
                    // 分隔
                    type: "pictorialBar",
                    itemStyle: {
                        color: "#07314a",
                    },
                    symbolRepeat: "fixed",
                    symbolMargin: this.graphicRatio(2),
                    symbol: "rect",
                    symbolClip: true,
                    symbolSize: [this.graphicRatio(3), this.graphicRatio(28)],
                    symbolPosition: "start",
                    symbolOffset: [this.graphicRatio(3), this.graphicRatio(-4)],
                    symbolBoundingData: this.total,
                    data: [total, total, total],
                    z: 2,
                    animationEasing: "elasticOut",
                },
                {
                    type: "pictorialBar",
                    symbolBoundingData: total,
                    itemStyle: {
                        color: "none",
                    },
                    label: {
                        fontSize: this.graphicRatio(12),
                        formatter: (params) => {
                            // console.log("params", params);
                            var text;
                            if (params.data == 0) {
                                text = "0 %";
                            } else {
                                text =
                                    "{f| " + ((params.data * 100) / total).toFixed(2) + "%}";
                            }

                            return text;
                        },
                        rich: {
                            fontSize: this.graphicRatio(12),
                            f: {
                                color: "#ffffff",
                                fontSize: this.graphicRatio(12),
                            },
                        },
                        position: ['104%', '27%'],
                        distance: this.graphicRatio(15), // 向右偏移位置
                        show: true,
                        color: "#fff",
                    },
                    data: datas,
                    z: 0,
                },

                {
                    name: "外框",
                    type: "bar",
                    barGap: "-130%", // 设置外框粗细
                    data: [total, total, total],
                    barWidth: this.graphicRatio(45),
                    itemStyle: {
                        borderRadius: [this.graphicRatio(5), this.graphicRatio(5), this.graphicRatio(5), this.graphicRatio(5)],
                        color: "transparent", // 填充色
                        borderColor: "rgba(0, 204, 255, 0.6)", // 边框色
                        borderWidth: this.graphicRatio(3), // 边框宽度
                    },
                    z: 0,
                },
                {
                    type: "scatter",
                    name: "条形",
                    symbol: "roundRect",
                    symbolSize: [this.graphicRatio(7), this.graphicRatio(20)],
                    symbolOffset: [this.graphicRatio(3), this.graphicRatio(-5)],
                    symbolKeepAspect: true,
                    itemStyle: {
                        // normal: {
                        color: "rgba(0, 204, 255, 0.6)",
                        // }
                    },
                    data: [total, total, total],
                },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //我的培训
        drawMyFont() {
            this.$echarts.init(document.getElementById("myFont")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("myFont"));
            const dataArr = [{
                value: Number(this.essential_myWords) / Number(this.tea_wordsNum),
                name: "活动完成率",
            },];
            const color = new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                offset: 0,
                color: "rgba(215, 83, 3, 1)", // 0% 处的颜色
            },
            {
                offset: 1,
                color: "rgba(255, 199, 27, 1)", // 100% 处的颜色
            },
            ]);
            const colorSet = [
                [Number(this.essential_myWords) / Number(this.tea_wordsNum), color],
                [Number(this.tea_wordsNum), "#15337C"],
            ];
            // console.log('colorSet',colorSet)
            const rich = {
                white: {
                    fontSize: this.graphicRatio(30),
                    color: "#fff",
                    fontWeight: "500",
                },
                bule: {
                    fontSize: this.graphicRatio(25),
                    fontFamily: "DINBold",
                    color: "#fff",
                    fontWeight: "700",
                },
                radius: {
                    width: this.graphicRatio(350),
                    height: this.graphicRatio(80),
                    borderWidth: this.graphicRatio(1),
                    borderColor: "#0092F2",
                    fontSize: this.graphicRatio(10),
                    color: "#fff",
                    backgroundColor: "#1B215B",
                    borderRadius: this.graphicRatio(20),
                    textAlign: "center",
                },
                size: {
                    height: this.graphicRatio(400),
                    padding: [this.graphicRatio(100), 0, 0, 0],
                },
            };
            myChart.setOption({
                tooltip: {
                    formatter: "{a} <br/>{b} : {c}%",
                },

                series: [{
                    type: "gauge",
                    radius: "85%",
                    startAngle: "225",
                    endAngle: "-45",
                    pointer: {
                        show: false,
                    },
                    detail: {
                        formatter: function (value) {
                            // console.log("value+++++++++", value);
                            var num = value.toFixed(1);
                            // console.log("num+++++++++", num);
                            return "{bule|" + num + "}{white|%}";
                        },
                        rich: rich,
                        offsetCenter: ["0%", "0%"],
                    },

                    data: dataArr,
                    title: {
                        show: true,
                        color: "#fff",
                        offsetCenter: ["0", "75%"],
                        fontSize: this.graphicRatio(12),
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: colorSet,
                            width: this.graphicRatio(25),
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            opacity: 1,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                        length: 35,
                        lineStyle: {
                            color: "#00377a",
                            width: this.graphicRatio(2),
                            type: "solid",
                        },
                    },
                    axisLabel: {
                        show: false,
                    },
                },],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //调出弹窗--停止滚动
        fatherMethod(value) {
            this.centerDialogVisible = true;
            this.dialog_words = value;
        },
        add() {
            this.$refs.child.magnify_start();
        },
        // 基础数据
        async basicsStatistics() {
            this.essential_count = 0;
            this.essential_live = 0;
            this.essential_myWords = 0;
            this.essential_StuWords = 0;
            this.essential_schoolBeginsA = 0;
            this.activity_amount = 0;
            this.issue_amount = 0;
            this.like_amount = 0;
            this.category = [];
            this.tea_category = [];
            let data = {
                chart: 3,
                level: 7,
                bindId: this.class_bindId,
                termKey: this.termValue,
            };
            let resData = await this.$Api.DataScreen.basicsStatistics(data);
            // console.log("基础数据---------", resData.data);
            // 基础数据
            this.essential_count = resData.data.count_student.result;
            this.essential_live = resData.data.count_live.result;
            this.essential_myWords = resData.data.count_learn.result;
            this.essential_StuWords = resData.data.count_student_works.result;
            this.essential_classTime = resData.data.count_class_length.result;
            this.essential_schoolBeginsA = resData.data.count_class_time.result;
            this.activity_amount = resData.data.count_learn.result;
            this.issue_amount = resData.data.count_stage.result;
            this.like_amount = resData.data.count_like.result;
            //学生作品等级分布
            this.category = [{
                name: resData.data.count_promote_works.name,
                value: resData.data.count_promote_works.result,
            },
            {
                name: resData.data.count_good_works.name,
                value: resData.data.count_good_works.result,
            },
            {
                name: resData.data.count_excellent_works.name,
                value: resData.data.count_excellent_works.result,
            },
            ];
            this.drawGrade();

            // 我的作品评价
            this.tea_category = [{
                name: resData.data.count_teacher_excellent_works.name,
                value: resData.data.count_teacher_excellent_works.result,
            },
            {
                name: resData.data.count_teacher_good_works.name,
                value: resData.data.count_teacher_good_works.result,
            },
            {
                name: resData.data.count_teacher_promote_works.name,
                value: resData.data.count_teacher_promote_works.result,
            },
            ];
            // 查询教师信息
            this.queryTeacherInfo();
        },
        //学生待提升最多的字
        async queryWork2Word() {
            let that = this;
            this.promoteData = [];
            this.promoteName = [];
            let data = {
                orgId: this.class_orgId,
            };
            let resData = await this.$Api.DataScreen.queryWork2Word(data);
            if (resData.data.length != 0) {
                this.elevate_show = true;
                for (let i = 0; i < resData.data.length; i++) {
                    this.promoteData.push(resData.data[i].count);
                    this.promoteName.push(resData.data[i].name);
                }
                this.$nextTick(() => {
                    that.drawElevate();
                });
            } else {
                this.elevate_show = false;
            }
        },
        //查询教师信息
        async queryTeacherInfo() {
            this.essential_teacherNameList = [];
            this.teacherId = "";
            this.essential_className = "";
            this.essential_teacherName = "";
            this.essential_schoolBegins = 0;
            this.dailyTencent = 0;
            let data = {
                orgId: this.class_orgId,
                termKey: this.termValue,
            };
            let resData = await this.$Api.DataScreen.queryTeacherInfo(data);

            this.essential_portrait = resData.data.avatar;
            this.essential_className = resData.data.className;
            this.essential_teacherName = resData.data.teacherName;
            if (resData.data.teacherNameList.length > 3) {
                this.essential_teacherNameList = resData.data.teacherNameList.slice(0, 3);
                this.essential_teacherNameList.push('...')
            } else {
                this.essential_teacherNameList = resData.data.teacherNameList
            }

            this.essential_schoolBegins = resData.data.dailyClass;
            this.dailyTencent = resData.data.dailyTencent;
            this.teacherId = resData.data.userId;
            this.queryTrainInfo();
        },
        //最近7次上课统计
        async queryLastSevenClass() {
            this.lengthData = [];
            this.clasTime_Sum = 0;
            this.joinRateData = [];
            this.rightRateData = [];
            this.characterData = [];
            this.interactionData = [];
            let data = {
                orgId: this.class_orgId,
            };
            let that = this;
            let resData = await this.$Api.DataScreen.queryLastSevenClass(data);
            if (resData.data.length != 0) {
                this.direct_class_show = false;
                for (let i = 0; i < resData.data.length; i++) {
                    this.lengthData.push(Number((resData.data[i].length / 60).toFixed(2)));
                    this.clasTime_Sum += Number((resData.data[i].length / 60).toFixed(2));
                    this.joinRateData.push(
                        Number((resData.data[i].joinRate * 100).toFixed(2))
                    );
                    this.rightRateData.push(resData.data[i].rightRate);
                    this.characterData.push(resData.data[i].name);
                    this.interactionData.push(resData.data[i].countJoin)
                }

                //上课时长总和
                this.clasTime_Sum = this.clasTime_Sum.toFixed(2);
                this.$nextTick(() => {
                    that.drawRecent();
                });
            } else {
                this.direct_class_show = true;
            }

        },
        //学生互动参与情况
        async queryStudentJoinData() {
            this.participationData = [];
            let data = {
                orgId: this.class_orgId,
                termKey: this.termValue,
            };
            let resData = await this.$Api.DataScreen.queryStudentJoinData(data);
            // console.log("学生互动参与情况", resData);
            this.participationData = resData.data;
        },
        //查询作品动态列表
        async queryWorkData() {
            this.wordsDatas = [];
            let data = {
                bindId: this.class_bindId,
                level: 7,
                termKey: this.termValue,
            };
            let resData = await this.$Api.DataScreen.queryWorkData(data);
            // console.log("查询作品动态列表",);
            if (JSON.stringify(resData.data) == '{}') {
                this.wordsRoll_show = false;
            } else {
                this.wordsRoll_show = true;
                this.wordsDatas = resData.data;
            }

        },
        // 查询教师培训数据---我的作品评价 --- 我的培训
        async queryTrainInfo() {
            this.tea_excellentWord = "";
            this.tea_goodWord = "";
            this.tea_promoteWord = "";
            let data = {
                teacherId: this.teacherId,
            };
            let resData = await this.$Api.DataScreen.queryTrainInfo(data);
            this.tea_wordsNum = resData.data.countTotal;
            this.tea_excellentWord = resData.data.excellentWord;
            this.tea_goodWord = resData.data.goodWord;
            this.tea_promoteWord = resData.data.promoteWord;
            //我的培训
            this.drawMyFont();
        },
        //雷达图
        async getradar1() {
            this.write_indicator = [];
            this.write_Data = [];
            let type = [];
            let data = {
                chart: 2,
                level: 7,
                bindId: this.class_bindId,
                termKey: this.termValue,
            };
            let resData = await this.$Api.DataScreen.basicsStatistics(data);
            if (JSON.stringify(resData.data) == "{}") {
                // this.write_indicator = [{
                //     max: 100,
                //     name: '作品完整度'
                // },
                // {
                //     max: 100,
                //     name: '结构组合'
                // },
                // {
                //     max: 100,
                //     name: '章法占格'
                // },
                // {
                //     max: 100,
                //     name: '笔画形态'
                // },
                // ]
                this.write_indicator = [{
                    max: 100,
                    name: '章法占格'
                },
                {
                    max: 100,
                    name: '作品完整度'
                },
                {
                    max: 100,
                    name: '笔画形态'
                },
                {
                    max: 100,
                    name: '结构组合'
                },
                ]
                this.write_Data = [0, 0, 0, 0, 0, 0, 0, 0, 0]
                this.drawRadar1()
                return false;
            }
            Object.keys(resData.data)
                .sort()
                .forEach((item) => {
                    this.write_indicator.push({
                        name: resData.data[item].name,
                    });
                    if (resData.data[item].count == 0) {
                        this.write_Data.push(0);
                    } else {
                        this.write_Data.push(
                            Math.round(
                                Number(resData.data[item].result) /
                                Number(resData.data[item].count)
                            )
                        );
                    }
                });
            let max = this.write_Data[0];
            for (let i = 1; i < this.write_Data.length; i++) {
                max = max > this.write_Data[i] ? max : this.write_Data[i];
            }
            for (let i = 0; i < this.write_indicator.length; i++) {
                this.write_indicator[i].max = 100;
            }
            this.write_indicator = [
                this.write_indicator[2],
                this.write_indicator[0],
                this.write_indicator[3],
                this.write_indicator[1],
            ],
                this.write_Data = [
                    this.write_Data[2],
                    this.write_Data[0],
                    this.write_Data[3],
                    this.write_Data[1],
                ]
            this.drawRadar1();
        },
        async getradar2() {
            this.Hanzi_indicator = [];
            this.Hanzi_Data = [];
            let data = {
                chart: 1,
                level: 7,
                bindId: this.class_bindId,
                termKey: this.termValue,
            };
            let resData = await this.$Api.DataScreen.basicsStatistics(data);
            // console.log("雷达图2", resData.data);
            if (JSON.stringify(resData.data) == "{}") {
                this.Hanzi_Data = [0, 0, 0, 0, 0];
                this.Hanzi_indicator = [{
                    name: '握笔',
                    max: 100
                },
                {
                    name: '坐姿',
                    max: 100
                },
                {
                    name: '音、形、义',
                    max: 100
                },
                {
                    name: '偏旁部首、结构',
                    max: 100
                },
                {
                    name: '笔顺笔画',
                    max: 100
                },
                ]
                this.drawRadar2();
                return false;
            }
            Object.keys(resData.data)
                .sort()
                .forEach((item) => {
                    this.Hanzi_indicator.push({
                        name: resData.data[item].name,
                    });
                    if (resData.data[item].count == 0) {
                        this.Hanzi_Data.push(0);
                    } else {
                        this.Hanzi_Data.push(
                            Math.round(
                                Number(resData.data[item].result) /
                                Number(resData.data[item].count)
                            )
                        );
                    }
                });
            // console.log("this.Hanzi_Data", this.Hanzi_Data);
            let max = this.Hanzi_Data[0];
            for (let i = 1; i < this.Hanzi_Data.length; i++) {
                max = max > this.Hanzi_Data[i] ? max : this.Hanzi_Data[i];
            }
            for (let i = 0; i < this.Hanzi_indicator.length; i++) {
                this.Hanzi_indicator[i].max = 100;
            }
            this.drawRadar2();
        },
        close() {
            this.centerDialogVisible = false;
            this.$refs.child.magnify_start();
        },
        //判断图片加载
        //教师
        onErrorImg() {
            this.essential_portrait = false;
        },
        onErrorImg2(i) {
            if (i == 0) {
                this.participateInType0 = false;
            } else if (i == 1) {
                this.participateInType1 = false;
            } else {
                this.participateInType2 = false;
            }
            // this.participateInType = false;
        },

        async gradequeryOrgInfo(userId, orgId) {
            let schoolId = sessionStorage.getItem('schoolId');
            let data = {
                schoolId: this.$route.query.schoolId || schoolId,
                termKey: this.termValue,
            }
            let resData = await this.$Api.DataScreen.queryTeacherClassSelect(data);
            //年级班级筛选框
            this.gradeoptions = resData.data;
            this.classoptions = resData.data[0].sonOrgList;
            if (this.$route.query.type == "class" && this.$route.query.orgId && this.$route.query.bindId) {
                let gradeId = '';
                let name = ''
                for (let i = 0; i < this.gradeoptions.length; i++) {
                    try {
                        for (let j = 0; j < this.gradeoptions[i].sonOrgList.length; j++) {
                            if (this.gradeoptions[i].sonOrgList[j].bindId == this.$route.query.bindId) {
                                gradeId = this.gradeoptions[i].orgId;
                                name = this.gradeoptions[i].sonOrgList[j].name;
                                // this.classvalue = name;
                                break
                            }
                        }
                    } catch (err) {
                        console.log('err:', err)
                    }

                }
                // this.classvalue = this.$route.query.classname;
                this.gradevalue = this.gradeoptions.find(item => item.orgId == gradeId).name;
                this.classvalue = name;
                this.class_bindId = this.$route.query.bindId;
                this.class_orgId = this.$route.query.orgId;
            } else {
                this.gradevalue = resData.data[0].name;
                this.classvalue = resData.data[0].sonOrgList[0].name;
                this.class_bindId = resData.data[0].sonOrgList[0].bindId;
                this.class_orgId = resData.data[0].sonOrgList[0].orgId;
                this.replace_index == 1 ? this.$refs.myChild_stuUse.receive(this.termValue, this.class_bindId, this.classvalue) : '';
            }
            this.$emit("childClick", this.classvalue);
            this.aggregate();

        },
        // 选择年级
        gradeexecute() {
            let arr = this.gradeoptions.filter((i) => {
                return this.gradevalue == i.name;
            });
            this.classoptions = arr[0].sonOrgList;
            this.classvalue = this.classoptions[0].name;
            this.$emit("childClick", this.classvalue);
            this.class_bindId = this.classoptions[0].bindId;
            this.class_orgId = this.classoptions[0].orgId;
            this.nameV = '全部';
            this.options = [{
                id: '',
                name: "全部",
                sex: '',
            }]
            this.aggregate();
            this.replace_index == 1 ? this.$refs.myChild_stuUse.receive(this.termValue, this.class_bindId, this.classvalue) : '';
        },
        // 选择班级
        classexecute() {
            let arr = this.classoptions.filter((i) => {
                return this.classvalue == i.name;
            });
            this.$emit("childClick", this.classvalue);

            // console.log("arr", arr);
            this.class_bindId = arr[0].bindId;
            this.class_orgId = arr[0].orgId;
            this.nameV = '全部';
            this.options = [{
                id: '',
                name: "全部",
                sex: '',
            }]
            this.aggregate();
            this.replace_index == 1 ? this.$refs.myChild_stuUse.receive(this.termValue, this.class_bindId, this.classvalue) : '';
        },
        //雷达图学生下拉
        changeStu() {
            const data = this.options.find(item => item.name == this.nameV);
            if (this.nameV == '全部') {
                this.getradar1();
                this.getradar2();
            } else {
                this.getCharacterWritingLevel(data.id);
                this.getCharacterAttainmentLevel(data.id);
            }

            // this.nameV
            // this.options
        },
        //请求集合
        aggregate() {
            if (this.replace_index == 0) {
                //基础数据 -- 学生作品等级分布
                this.basicsStatistics();
                //近30天数据
                this.getAttendClassNum();
                //最近7次上课统计
                this.queryLastSevenClass();
                //学生互动参与情况
                this.queryStudentJoinData();
                //学生待提升最多的字
                this.queryWork2Word();
                //获取学生列表
                this.getUsersList()
                //雷达图 判断是全部还是学生
                this.getradar1();
                this.getradar2();
                //查询作品动态列表
                this.queryWorkData();
            }


        },
        //获取学年学期列表 this.$Api.DataScreen
        getTermList() {
            this.$Api.DataScreen.queryTermList()
                .then(res => {
                    let that = this;
                    res.data.map((item, index) => {
                        that.termOptions.push({
                            value: item.key,
                            label: item.termName
                        });
                    });
                    this.termValue = that.termOptions[0].value;
                    this.aggregate()
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        // 选择学年
        termChange(value) {
            this.termValue = value;
            this.gradeoptions = [];
            this.classoptions = [];
            this.gradevalue = "";
            this.classvalue = "";
            this.nameV = '全部';
            this.options = [{
                id: '',
                name: "全部",
                sex: '',
            }];
            this.gradequeryOrgInfo();
        },
        //本班上课总数按天统计最近30天
        async getAttendClassNum() {
            let data = {
                classId: this.class_bindId,
                termKey: this.termValue,
            };
            let that = this;
            let date = [];
            let value = [];
            let resData = await this.$Api.DataScreen.getAttendClassNum(data);
            if (resData.data.length != 0) {
                this.interaction_class_show = false;
                for (let i = 0; i < resData.data.length; i++) {
                    date.push(resData.data[i].logTime)
                    value.push(resData.data[i].num)
                }
                this.$nextTick(() => {
                    that.drawImmediate(date, value);
                })
            } else {
                this.interaction_class_show = true;
            }

        },
        //获取学生列表
        async getUsersList() {
            let schoolId = sessionStorage.getItem('schoolId');
            let data = {
                orgId: this.class_orgId,
                termKey: this.termValue,
                // classId: this.class_bindId,
                // schoolId: this.$route.query.schoolId || schoolId,
            }
            let resData = await this.$Api.DataScreen.getUsersList(data);

            this.options = this.options.concat(resData.data)
        },
        //汉字书写水平（学生个人）接口
        async getCharacterWritingLevel(id) {
            this.write_indicator = [];
            this.write_Data = [];
            let data = {
                userId: id,
                termKey: this.termValue,
            }
            let resData = await this.$Api.DataScreen.getCharacterWritingLevel(data);
            // consistency -- 占格一致性
            // integrity -- 作品完整度
            // layout -- 布局整齐度
            // similarity -- 例字相似性
            this.write_indicator = [{
                max: 100,
                name: '章法占格'
            },
            {
                max: 100,
                name: '作品完整度'
            },
            {
                max: 100,
                name: '笔画形态'
            },
            {
                max: 100,
                name: '结构组合'
            },
            ]
            this.write_Data = Object.values(resData.data);
            this.drawRadar1()

        },
        //汉字素养水平（学生个人）接口
        async getCharacterAttainmentLevel(id) {
            let data = {
                userId: id,
                termKey: this.termValue,
            }
            let resData = await this.$Api.DataScreen.getCharacterAttainmentLevel(data);
            console.log('resData', resData)
            //holdPen -- 握笔
            //sittingPosition -- 坐姿
            //sound -- 音、形、义
            //structure -- 偏旁部首、结构
            //strokeOrder -- 笔顺笔画
            this.Hanzi_indicator = [{
                name: '握笔',
                max: 100
            },
            {
                name: '坐姿',
                max: 100
            },
            {
                name: '音、形、义',
                max: 100
            },
            {
                name: '偏旁部首、结构',
                max: 100
            },
            {
                name: '笔顺笔画',
                max: 100
            }];
            this.Hanzi_Data = Object.values(resData.data);
            this.drawRadar2()


        },
        recentShow_click1() {
            this.recentShow = false;
            // this.immediateShow = true;
            this.getAttendClassNum();
        },
        recentShow_click2() {
            this.recentShow = true;
            // this.immediateShow = false;
            this.queryLastSevenClass();
        },
        nav_chang(index) {
            this.replace_index = index;
            this.testBtn = false;
            // index == 2 ? '' : this.replace_index = index;
            if (this.replace_index == 0) {
                if (this.$route.query.type == "class" && this.$route.query.inside != 0) {
                    this.gradequeryOrgInfo();

                } else {
                    this.class_bindId = this.$route.query.bindId;
                    this.class_orgId = this.$route.query.orgId;
                    this.getTermList();
                }
            }
        },
        gotestBtn() {
            this.testBtn = true;
            let schoolId = sessionStorage.getItem('schoolId') || this.$route.query.schoolId;
            let schoolName = sessionStorage.getItem('schoolName')
            window.open(`/newreport?type=class&choolId=${schoolId}&schoolName=${schoolName}&fromType=multiRole&showType=1&date=${this.termValue}`, "_blank");
        },

    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {
        if (this.$route.query.type == "class" && this.$route.query.inside != 0) {
            this.$Api.DataScreen.queryTermList()
                .then(res => {
                    let that = this;
                    if (that.$route.query.role == 3) {
                        res.data.map((item, index) => {
                            if (item.key == this.$route.query.termValue) {
                                that.termOptions.push({
                                    value: item.key,
                                    label: item.termName
                                });
                            }
                        });
                        this.$route.query.termValue ? this.termValue = Number(this.$route.query.termValue) : this.termValue = that.termOptions[0].value;
                    } else {
                        res.data.map((item, index) => {
                            that.termOptions.push({
                                value: item.key,
                                label: item.termName
                            });
                        })
                        this.termValue = that.termOptions[0].value;
                    }
                    this.gradequeryOrgInfo();
                })
                .catch(err => {
                    console.log('err:', err)
                })

        } else {
            this.class_bindId = this.$route.query.bindId;
            this.class_orgId = this.$route.query.orgId;
            this.termValue = this.$route.query.termValue;
            this.aggregate();
            // this.getTermList();

        }

    },
};
</script>
<style lang="less" scoped>
@import "./less/classData.less";
</style>